<template>
  <list-container-widget
    :id="`${collectiveFilter}`"
    :title="widgetName || $t(`collectives.${collectiveFilter}.title`)"
    :app-id="appId"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target">TARGET!!! CollectivesListWidget - {{ targetIsVisible }}</div> -->
      <!-- Panel actions -->
      <!-- <widget-actions
        :item-type="`${collectiveFilter}`"
        :show-list="true"
        :show-add="canCreate || isStaff"
        @updateSearch="searchItems"
        :show-filter="false"
      /> -->
      <ViewAllButton :item-type="collectiveFilter" />
      
    </template>

    <!-- Loading -->
    <b-card-body>
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <div v-if="!canList">
        <div>
          You can't see the communities.
        </div>
      </div>

      <!-- Container with data -->
      <div v-else-if="communities.length > 0">
        <div ref="container" class="x-scroll-container" @scroll="onScroll">
          <!-- Items Loop -->
          <collective-card
            v-for="community in communities"
            :key="community.key"
            class="x-scroll-element mx-1 mb-2"
            :collective="community"
          />

          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col v-if="placeholder" cols="12">
          <img :src="placeholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: collectiveFilter }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>

  </list-container-widget>
</template>

<script>
import CommunitiesPlaceholder from "@/assets/images/placeholders/light/communities.svg";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
import { checkPermissions } from "@/@core/utils/roles-utils";
import CollectiveFilter from "../constants/CollectiveFilter";
import CollectiveCard from "../components/CollectiveCard.vue";
import LanguageSelectorHeader from "@core/components/modal/LanguageSelectorHeader.vue";
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core';

export default {
  name: "CollectivesListWidget",

  components: {
    ListContainerWidget,
    WidgetActions,
    CollectiveCard,
    MembershipEventCreateModal,
    LanguageSelectorHeader,    
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  mixins: [WidgetLayoutMixin],

  props: {
    collectiveFilter: {
      type: String,
      required: true,
      validator(collectiveFilter) {
        return Object.values(CollectiveFilter).includes(collectiveFilter);
      },
    },
  },

  data() {
    return {
      isContactModalVisible: false,
      isLoading: false,
      isLoadingNextPage: false,
      perPage: 20,
      lastLoadedPage: 1,
      searchInput: "",
      results: [],
      selectedLanguage: this.currentLocale,
    };
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("WATCH!!! --> CollectivesListWidget.vue  (targetIsVisible): ", this.collectiveFilter, newValue)
      if (newValue && !this.communities?.length) {
        // console.log("2 CollectivesListWidget --> WATCH!!! (targetIsVisible): ", this.collectiveFilter, newValue)
        this.fetchData();
      }
    }
  },

  computed: {
    appId() {
      switch (this.collectiveFilter) {
        case "sections":
          return 42;
        case "subcommunities":
          return 67;
        case "courses":
          return 18;
        default:
          // should never happen.
          return null;
      }
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    canCreate() {
      let collectiveType = this.collectiveFilter;
      if (collectiveType === "subcommunities") {
        return (
          this.isStaff ||
          checkPermissions(
            "create",
            "subcommunity",
            this.loggedMemberRoles,
            this.collective
          )
        );
      } else {
        collectiveType = collectiveType.substring(0, collectiveType.length - 1);
        return (
          this.isStaff ||
          checkPermissions(
            "create",
            collectiveType,
            this.loggedMemberRoles,
            this.collective
          )
        );
      }
    },
    canList() {
      return true;

      let collectiveType = this.collectiveFilter;
      if (collectiveType === "subcommunities") {
        return (
          this.isStaff ||
          checkPermissions(
            "index",
            "subcommunity",
            this.loggedMemberRoles,
            this.collective
          )
        );
      } else {
        collectiveType = collectiveType.substring(0, collectiveType.length - 1);

        return (
          this.isStaff ||
          checkPermissions(
            "index",
            collectiveType,
            this.loggedMemberRoles,
            this.collective
          )
        );
      }
    },
    ListContainer() {
      return this.ListContainer;
    },
    communitiesData() {
      // console.log('this.$store.getters', this.$store.getters);
      // console.log('this.$store.getters.spaces', this.$store.getters.spaces);
      return this.$store.getters.spaces[this.collectiveFilter];
      //return this.$store.getters.communitiesOthers[this.collectiveFilter];
    },
    communities() {
      if (this.communitiesData) {
        return this.searchInput ? this.results : this.communitiesData?.unpaginated;
      }
      // if (this.$store.getters.communitiesOthers[this.collectiveFilter]) {
      //   return this.$store.getters.communitiesOthers[this.collectiveFilter]?.unpaginated;
      // }
      if (this.$store.getters.spaces[this.collectiveFilter]) {
        return this.$store.getters.spaces[this.collectiveFilter]?.unpaginated;
      }
      return [];
    },
    total() {
      //return this.$store.getters.communitiesOthers[this.collectiveFilter]?.meta.total;
      return this.$store.getters.spaces[this.collectiveFilter]?.meta.total;
    },
    itemName() {
      return this.$t(`collectives.${this.collectiveFilter}.title`);
    },
    placeholder() {
      return CommunitiesPlaceholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
  },

  // async created() {
  //   // console.log('CREATED!!!!!!! CollectivesListWidget.vue')
  //   this.isLoading = true;
  //   await this.fetchData();
  //   this.isLoading = false;
  // },

  methods: {
    async fetchData(page = 1) {
      this.isLoading = true;
      if (this.page !== page) {
        this.isLoadingNextPage = true;
      }
      this.page = page;
      let mainTypeId = 7;
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
      };
      //console.log('this.collectiveFilter', this.collectiveFilter);
      if (this.collectiveFilter === "sections") {
        requestConfig.isSection = true;
        mainTypeId = 6;
      }
      if (this.collectiveFilter === "courses") {
        requestConfig.isCourse = true;
        mainTypeId = 2;
      }
      if (this.collectiveFilter === "events") {
        requestConfig.isEvent = true;
        mainTypeId = 1;
      }
      // await this.$store.dispatch("getItems", {
      //   itemType: "communities/simply",
      //   customName: "communitiesOthers",
      //   storedKey: this.collectiveFilter,
      //   page: this.lastLoadedPage,
      //   perPage: this.perPage,
      //   requestConfig,
      // });
      const items = await this.$store.dispatch("getItems", {
        itemType: "spaces",
        customName: "spaces",
        storedKey: this.collectiveFilter,
        page: this.lastLoadedPage,
        perPage: this.perPage,
        requestConfig: {
          parentSlug: this.$store.getters.currentCollective.slug,
          withViewerMembership: 1,
          mainTypeID: mainTypeId
        },
      });
      console.log('CollectivesListWidget.vue - items:', items);
      this.isLoading = false;
      this.isLoadingNextPage = false;
      return items;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    // Load more items
    async handleLoadOfNewItems() {
      if (!this.isLoading && this.communities?.length < this.total) {
        this.lastLoadedPage += 1;
        await this.fetchData();
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.communitiesData.unpaginated.filter((item) =>
        {
          let itemName = item?.name;
          if(typeof itemName === 'object'){
            itemName = translateTranslationTable(this.currentLocale, itemName);
          }
          if(itemName?.toLowerCase().includes(searchTerm)){
            return item;
          }
        }
      );
    },
    openModal() {
      this.$bvModal.show(`modal-create-${this.collectiveFilter}`);
    },
    closeModal() {
      this.$bvModal.hide(`modal-create-${this.collectiveFilter}`);
    },
  },
};
</script>
